import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppContent from './components/AppContent';
import CampaignBuilder from './components/CampaignBuilder';
import CampaignReport from './components/CampaignReport';
import Participants from './components/Participants'; 
import ReportsPage from './components/Participants'; 
import KnowledgeBasePage from './components/Participants'; 
import './App.css';

const App = () => {
  return (
    <Routes>
      <Route path="/report/:campaignId" element={<CampaignReport />} />
      <Route path="/research/:campaignId?" element={<CampaignBuilder />} />
      <Route path="/participants" element={<Participants />} /> 
      <Route path="/*" element={<AppContent />} />
    </Routes>
  );
};

export default App;