import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MdHome, MdBarChart, MdSearch, MdChevronLeft, MdChevronRight, 
  MdAccountCircle, MdContentCopy, MdMoreVert, MdMenuBook, MdArrowDropUp, MdArrowDropDown, 
  MdNewLabel, MdLabel, MdArrowRight, MdArrowLeft,MdExitToApp , MdArrowUpward} from 'react-icons/md';
import logo from '../assets/echovane_logo.png';
import mixpanel from 'mixpanel-browser';
import ConfirmDialog from './ConfirmDialog';
import consumer_research from '../assets/consumer_research_color.svg';
import customer_feedback from '../assets/customer_feedback_color.svg';
import how_to from '../assets/how_to.png';
import create_new from '../assets/create_new.svg';
import business_objective from '../assets/business_objective.png';

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });



const HomePage = (props) => {

  const { interviewMinutesUsed, interviewMinutesLimit, planType } = props; 
  
  useEffect(() => {
    mixpanel.track('DashboardHomePageViewed');
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(true); // For expanding and collapsing drawer
  const [userMenuOpen, setUserMenuOpen] = useState(false); // For user dropdown
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };
  
  
  

  const [campaigns, setCampaigns] = useState([]);
  const [backendAuthComplete, setBackendAuthComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState(0);
  const pageSize = 6; 
  const [copiedMessage, setCopiedMessage] = useState('');
  const [menuOpen, setMenuOpen] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const menuRef = useRef(null);
  const [deleteError, setDeleteError] = useState(null);
  const campaignsFetched = useRef(false);
  const [showText, setShowText] = useState(true);
  const drawerRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [dropdownDirection, setDropdownDirection] = useState('below');
  


  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();


  const handlePlansClick = () => {
    navigate('/plans');
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) && 
        !event.target.closest('.menu-button') 
      ) {
        setMenuOpen(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (!isLoading && !isAuthenticated) {
        try {
          await loginWithRedirect({
            appState: { returnTo: window.location.pathname }
          });
        } catch (error) {
          console.error('Login error:', error);
          setError('Failed to log in. Please try again.');
        }
      }
    };

    checkAndRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const fetchTokens = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
        
          setAccessToken(accessToken);
          setIdToken(idTokenClaims.__raw);
        } catch (error) {
          console.error('Error fetching tokens:', error);
          setError('Failed to fetch authentication tokens. Please try logging in again.');
        }
      }
    };

    fetchTokens();
  }, [isAuthenticated, user]);

  const handleCampaignReportClick = (campaign) => {
    if (accessToken && idToken) {
      navigate(`/report/${campaign.id}`, { 
        state: { 
          accessToken, 
          idToken,
          testLink: campaign.test_link,
          liveLink: campaign.live_link
        } 
      });
    } else {
      console.error('Tokens are not available');
    }
  };
  
  const handleViewParticipants = (campaign) => {
    navigate('/participants', { state: { campaignId: campaign.id } });
  };


  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000); // Hide error message after 3 seconds
  };
  

  const toggleMenu = (campaignId) => {
    setMenuOpen(menuOpen === campaignId ? null : campaignId);
  };

  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setShowDeleteConfirm(true);
    setMenuOpen(null);
  };

  const handleDeleteConfirm = async () => {
    if (campaignToDelete) {
      try {
        const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignToDelete.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        });
  
        if (response.ok) {
          setCampaigns(campaigns.filter(c => c.id !== campaignToDelete.id));
          mixpanel.track('CampaignDeleted', { campaignId: campaignToDelete.id });
          setMenuOpen(null); 
          setDeleteError(null); 
          setShowDeleteConfirm(false);
        } else {
          const errorData = await response.json();
          showErrorMessage(errorData.message || 'Failed to delete campaign');
        }
      } catch (error) {
        console.error('Error deleting campaign:', error);
        showErrorMessage('An error occurred while deleting the campaign');
      }
    }
    setCampaignToDelete(null);
  };


  useEffect(() => {
    if (campaignsFetched.current) {
      return;
    }

    const fetchUserDataandCampaigns = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
    
          const response = await fetch('https://dashboard-api.echovane.com/api/auth/user', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken.__raw,
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              email: user.email,
            })
          });
          
          
          const responseData = await response.json();
  
          

          if (response.status === 200) {
            setBackendAuthComplete(true);
            fetchCampaigns(currentPage);
            campaignsFetched.current = true;
          } else {
            console.error('Backend authentication failed:', responseData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
  
    fetchUserDataandCampaigns();
  }, [isAuthenticated, user]);

  
  const handleCopyLink = (link, type) => {
    navigator.clipboard.writeText(link);
    setCopiedMessage(`${type} link copied`);
    
    setTimeout(() => {
      setCopiedMessage('');
    }, 3000); // Hide message after 3 seconds
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };


  const fetchCampaigns = async (page = 1) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      
      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns?page=${page}&page_size=${pageSize}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken.__raw
        }
      });
      if (response.ok) {
        const data = await response.json();
        // console.log("responseData ",data );
        
        setCampaigns(data.campaigns);
        setTotalCount(data.total_count);
        setTotalPages(Math.ceil(data.total_count / pageSize));
        setCurrentPage(page);
      } else {
        console.error('Failed to fetch campaigns');
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setPaginationLoading(false);
      setLoading(false);
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true); 
      fetchCampaigns(currentPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
        setPaginationLoading(true); 
      fetchCampaigns(currentPage + 1);
    }
  };

  const handleUpgradeClick = () => {
    navigate(`/subscriptions`);
  };
  

  const handleCampaignClick = (campaign) => {
    navigate(`/research/${campaign.id}`);
  };
  
  useEffect(() => {
    const drawer = drawerRef.current;

    if (drawer) {
    if (drawerOpen) {
      // Wait for the drawer transition to end before showing text
      const handleTransitionEnd = () => {
        setShowText(true);
      };
      
      // Add event listener to wait for the transition end
      drawer.addEventListener('transitionend', handleTransitionEnd);
  
      return () => {
        // Clean up event listener when the component unmounts or when drawer changes
       
        drawer.removeEventListener('transitionend', handleTransitionEnd);
      };
    } else {
      // Reset showText when the drawer is collapsing
      setShowText(false);
    }

  }
  }, [drawerOpen]);

  if (loading || isLoading ) {
    return <div className="loading-indicator-container">
              <div className="loading-indicator"></div>
            </div>;
  }

  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleCreateNewResearch = () => {
    navigate('/research');
  };

  

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  

  return (
    <>
      {loading ? null : (
        <div className="app-container">
          

          <main className="main-content">

            <div className='greeeting'>Good Afternoon</div>

            {interviewMinutesUsed >= interviewMinutesLimit && planType === 'Free' && (

            <div className='notification-banner-section'>
            <div className='notification-banner-text-content'>
            <div className='notification-banner-title'>

            Awesome work! You’ve used all your interview minutes for this month on the free tier 🎉
            </div>
            <div className='notification-banner-description'>

            Upgrade to keep gaining insights and make the most out of your interviews! 
            </div>
            </div>

            <div className='notification-banner-actions'>
              <button className='positive-action' onClick={handleUpgradeClick}> Upgrade</button>
              
            </div>
            </div>
            
          )}
            
            <div className='create-research-section'>
            <div className='create-research-title'> Create project</div>
            <div className='create-research-content'>
              <div className='create-research-item-new' onClick={handleCreateNewResearch}>

              <div className='create-research-item-content'>

                < img src={create_new} className='create-research-item-svg-new'></img>
                <p className='create-research-item-text'>From scratch</p>

              </div>
              </div>
              <div className='create-research-item' onClick={handleCreateNewResearch}>
              <div className='create-research-item-content'>

                < img src={consumer_research} className='create-research-item-svg'></img>
                <p className='create-research-item-text'>Consumer research</p>

              </div>

              </div>
              <div className='create-research-item' onClick={handleCreateNewResearch}>

              <div className='create-research-item-content'>

                < img src={customer_feedback} className='create-research-item-svg'></img>
                <p className='create-research-item-text'>User feedback</p>

              </div>
              </div>
            </div>
            </div>
            
            {campaigns.length === 0 ? (
              <div className="empty-state">
              </div>
            ) : (
              <>
                <div className="campaign-list-container" >
                <div className='create-research-title'> Your projects</div>
                  <div className={`campaign-list ${paginationLoading ? 'loading' : ''}`}>

                    <table>
                      <thead>
                        <tr>
                          <th>Research</th>
                          <th>Status</th>
                          <th>Last Updated</th>
                          <th>Actions</th>  
                        </tr>
                      </thead>


                      <tbody>
                      {paginationLoading ? (
                        [...Array(pageSize)].map((_, idx) => (
                            <tr key={idx}>
                            <td className="shimmer"></td>
                            <td className="shimmer"></td>
                            <td className="shimmer"></td>
                            <td className="shimmer"></td>
                            </tr>
                        ))
                        ) : ( <>
                        {campaigns.map((campaign) => (
                          <tr key={campaign.id}>
                            <td>
                              <a
                                href="#"
                                className="campaign-name-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCampaignClick(campaign);
                                }}
                              >
                                {campaign.name || 'Untitled Research'}
                              </a>
                            </td>
                            <td>
                              <span className={`status-chip ${campaign.status === 'Live' ? 'live' : 'draft'}`}>
                                {campaign.status}
                              </span>
                            </td>

                            <td>{campaign.updated_at}</td>
                            <td>
                              <div  className="action-menu">
                                <button onClick={() => toggleMenu(campaign.id)} className="menu-button">
                                  <MdMoreVert />
                                </button>
                                {menuOpen === campaign.id && (
                                  <div  ref={menuRef} className="menu-dropdown">
                                    <button onClick={() =>  {
                                        let link = `https://feedback.echovane.com/interviews/${campaign.live_link}`;

                                        if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                          const queryParams = campaign.identification_fields.fields
                                            .map(field => `${field}=xxxx`)
                                            .join('&');
                                          link += `?${queryParams}`;
                                        }

                                        handleCopyLink(link, 'Live');
                                      }} className="action-menu-option">Copy Live Link</button>
                                    <button onClick={() => {
                                      let link = `https://feedback.echovane.com/interviews/${campaign.test_link}`;

                                      // Check if identification method is 'link' and append fields with 'xxxx'
                                      if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                        const queryParams = campaign.identification_fields.fields
                                          .map(field => `${field}=xxxx`)
                                          .join('&');
                                        link += `?${queryParams}`;
                                      }

                                      handleCopyLink(link, 'Test');
                                    }} className="action-menu-option">Copy Test Link</button>
                                    {campaign.audience_option === 'recruit' && (
                                      <button onClick={() => handleViewParticipants(campaign)} className="action-menu-option">View Recruits</button>
                                    )}

                                    <button onClick={() => handleCampaignReportClick(campaign)} className="action-menu-option">View Report</button>
                                    <button onClick={() => handleDeleteClick(campaign)} className="action-menu-option">Delete</button>
                                  </div>
                                )}


                              </div>
                            </td>
                          </tr>
                        ))}
                        </>     )}
                      </tbody>
                      

                    </table>
                    

                  </div>
                  {total_count > pageSize && (
              <div className="pagination-controls">
                <MdArrowLeft                   onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="pagination-button"> </MdArrowLeft>

                <span>{currentPage} of {totalPages}</span>
                <MdArrowRight onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="pagination-button"></MdArrowRight>
              </div>
            )}
                </div>
              </>
            )}



            <div className='guides-section'>
              <div className='create-research-title'> Guides</div>
              <div className='guides-content'>
                <div className='guides-item'>
                <div className='guides-item-content'>

                  < img src={how_to} className='guides-item-content-svg'></img>
                  <p className='guides-item-content-text'>Mastering Research with Echovane: Top Tips for Smarter Insights</p>

                </div>
                </div>
                <div className='guides-item'>
                <div className='guides-item-content'>

                < img src={business_objective} className='guides-item-content-svg'></img>
                <p className='guides-item-content-text'>How to Write Clear and Effective Research Objectives</p>

                </div>
                </div>
                
              </div>
            </div>

          </main>
          {copiedMessage && (
              <div className="copied-message">
                {copiedMessage}
              </div>
            )}

        </div>
        
      )}
        <ConfirmDialog
          isOpen={showDeleteConfirm}
          onClose={() => {
            setShowDeleteConfirm(false);
            setDeleteError(null);
            setCampaignToDelete(null);
          }}
          onConfirm={handleDeleteConfirm}
          title="Are you sure you want to delete this campaign?"
          description="This action cannot be undone. This will permanently delete the campaign and all associated interviews and insight reports, if any."
        />
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
    </>
  );
  
}

export default HomePage;